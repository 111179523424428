import React from "react"
import PropTypes from "prop-types"
import "./styles.scss"

const iconClass = "material-icons"
const iconStyle = { color: "#E0E3E4", fontSize: "32px" }

const Document = ({ file }) =>
  !file.preview_image_url_sm ? (
    <span className={iconClass} style={iconStyle}>
      insert_drive_file
    </span>
  ) : (
    <div
      style={{
        height: "44px",
        backgroundImage: `url(${file.preview_image_url_sm})`
      }}
      className='bg-center bg-contain bg-no-repeat col-12'/>
  )

const DCP = () => (
  <span className={iconClass} style={iconStyle}>
    movie
  </span>
)

const Captions = () => (
  <span className={iconClass} style={iconStyle}>
    closed_caption
  </span>
)

const Subtitles = () => (
  <span className={iconClass} style={iconStyle}>
    subtitles
  </span>
)

const AudioTrack = () => (
  <span className={iconClass} style={iconStyle}>
    audiotrack
  </span>
)

const Disc = () => (
  <span className={iconClass} style={iconStyle}>
    album
  </span>
)

const Folder = () => (
  <span className={iconClass} style={iconStyle}>
    folder
  </span>
)

const Frozen = () => (
  <span className={iconClass} style={iconStyle}>
    ac_unit
  </span>
)

const SourceFile = ({ screener }) => (
  <span
    className={`white pointer flex items-center justify-center rounded bg-cover bg-center thumbnail ${
      !screener || !screener.thumbnail_url ? "placeholder" : ""
    }`}
    style={{
      width: "34px",
      height: "26px",
      backgroundImage: screener && screener.thumbnail_url ? `url(${screener.thumbnail_url})` : ``,
      backgroundColor: !screener || !screener.thumbnail_url ? `#ededed` : ``,
      fontSize: "14px"
    }}>
    <span style={{ fontSize: "14px" }} className='white material-icons'>
      play_arrow
    </span>
  </span>
)

Document.propTypes = {
  file: PropTypes.object.isRequired
}

SourceFile.defaultProps = {
  screener: {}
}

SourceFile.propTypes = {
  screener: PropTypes.object
}

export { Document, DCP, Captions, Subtitles, SourceFile, AudioTrack, Disc, Folder, Frozen }
