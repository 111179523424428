import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import ImportFileModal from "./actions/import_file_modal"
import FilesTable from "./components/table"
import Zip from "./components/zip"
import showAsperaWarning from "@helpers/showAsperaWarning"

const MAX_SIZE_FOR_ZIPPING = 26843545600 // 25GB

const ASPERA_WARNING = "If this content includes a DCP or large file structure, it may not download properly in a web browser. Please contact the sender to request an Aspera link, which is better suited for these types of files."

class Package extends React.Component {
  constructor() {
    super()

    this.state = {
      showZip: false
    }
  }
  render() {
    const { download, packageSize, status } = this.props
    return (
      <div>
        <h3 className='mb1 flex items-center justify-between'>
          <span>Shared Files: {download.project_title}</span>
          {/* {packageSize < MAX_SIZE_FOR_ZIPPING && status === "READY" && (
            <button className='cs-button white flex items-center' onClick={() => this.setState({ showZip: true })}>
              <i className='material-icons mr1'>folder</i> Download All
            </button>
          )} */}
        </h3>
        {download.method !== "aspera" && showAsperaWarning(download.file_objects) &&
          <div className='border border-gray-5 bg-white rounded p2 my2 flex items-center'>
            <span className='material-icons orange mr1'>warning</span>
            {ASPERA_WARNING}
          </div>}
        <FilesTable/>
        <ImportFileModal/>
        {this.state.showZip && <Zip onClose={() => this.setState({ showZip: false })}/>}
      </div>
    )
  }
}

Package.propTypes = {
  download: PropTypes.object.isRequired,
  packageSize: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired
}

const mapStateToProps = state => ({
  download: state.download_link,
  packageSize: state.download_link.files.list.reduce((p, c) => {
    return p + c.size
  }, 0),
  status: state.download_link.files.status
})

export default connect(mapStateToProps)(Package)
