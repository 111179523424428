const showAsperaWarning = files => {
  if (files.length > 1) {
    return true
  }
  if (["dcp", "folder"].includes(files[0].type)) {
    return true
  }
  return false
}

export default showAsperaWarning