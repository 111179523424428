import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { toggleModal } from "@api/shared_screeners/modals"
import { updateSharedScreener } from "@api/shared_screeners"
import DayPickerModal from "@src/helpers/dayPickerModal"
import dayjs from "dayjs"

const ExpiryDate = ({ sharedScreener, updateSharedScreener, toggleModal }) => (
  <DayPickerModal
    title={"Change expiry date for " + sharedScreener.shared_name}
    saveButtonText={"Update Expiry Date"}
    expiry={dayjs(sharedScreener.expiry).toDate()}
    onSave={date => updateSharedScreener(sharedScreener._id, "expiry", date)}
    onClose={() => toggleModal("expiryDate")}/>
)

ExpiryDate.propTypes = {
  sharedScreener: PropTypes.object.isRequired,
  updateSharedScreener: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired
}

const mapStateToProps = ({ shared_screeners }) => ({
  sharedScreener: shared_screeners.modals.expiryDate.sharedScreener
})

const mapDispatchToProps = dispatch => ({
  updateSharedScreener: bindActionCreators(updateSharedScreener, dispatch),
  toggleModal: bindActionCreators(toggleModal, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpiryDate)
