/**
 * Constants
 */
export const MAKE_PAYMENTS = "billing_and_plans:can_make_payments"
export const MANAGE_CREDIT_CARDS = "billing_and_plans:can_manage_credit_cards"
export const MODIFY_PLAN = "billing_and_plans:can_modify_plan"
export const VIEW_INVOICES = "billing_and_plans:can_view_invoices"
export const DOWNLOAD_FILES = "files:can_download"
export const DELETE_FILES = "files:can_delete"
export const SHARE_FILES = "files:can_share"
export const MODIFY_SETTINGS = "organization_settings:can_modify"
export const VIEW_SETTINGS = "organization_settings:can_view"
export const DELETE_PROJECTS = "projects:can_delete"
export const MODIFY_PROJECTS = "projects:can_modify"
export const VIEW_PROJECTS = "projects:can_view"
export const MANAGE_SCREENERS = "screeners:can_manage"
export const VIEW_SCREENER_ANALYTICS = "screeners:can_view_analytics"
export const SCREENERS_REQUIRE_WATERMARK = "screeners:require_watermark"
export const SCREENERS_REQUIRE_PASSWORD = "screeners:require_password"
export const VIEW_SCREENER_ROOMS = "screener_rooms:can_view"
export const MODIFY_SCREENER_ROOMS = "screener_rooms:can_modify"
export const SEND_SCREENER_ROOMS = "screener_rooms:can_send"
export const SCREENER_ROOMS_REQUIRE_WATERMARK = "screener_rooms:require_watermark"
export const SCREENER_ROOMS_REQUIRE_PASSWORD = "screener_rooms:require_password"
export const DELETE_USERS_AND_ROLES = "users_and_roles:can_delete"
export const MODIFY_USERS_AND_ROLES = "users_and_roles:can_modify"
export const VIEW_USERS_AND_ROLES = "users_and_roles:can_view"

/**
 * Check if a user can access a resource
 *
 * @param  Object  user     The authenticated user object (Must container the permissions object)
 * @param  Array   actions  An array of permissions to check against user. Each action must be true
 *                          For the function to return true.
 *
 * Usage example:
 *        import { can, VIEW_PROJECTS, MODIFY_PROJECTS } from '@src/helpers/authorization'
 *
 *        if(can(user, [VIEW_PROJECTS, MODIFY_PROJECTS])) {
 *            viewProject();
 *            editProject();
 *        }
 *
 * @return bool
 */
export const can = (user, actions = [], singlePermissionNeeded = false) => {
  if (!user.organization_id) {
    // Permissions are only relavent to Organizations.
    // Let's return true regardless of actions if no organization is present.
    return true
  }

  if (!actions.length) {
    return true
  }

  const filteredArr = actions.filter(action => user.permissions[action])

  if (singlePermissionNeeded) {
    return filteredArr.length > 0
  }
  else {
    return filteredArr.length === actions.length
  }
}

/**
 * The inverse of can()
 */
export const cannot = (user, actions = []) => !can(user, actions)

/**
 * Test if a user can access a project
 *
 * True if user is:
 * 		a) Their role can access projects
 * 		b) Their ID is associated with the projects user_ids array
 *		c) User does not have an organization
 *
 * @param {*} user
 * @param {*} project
 */
export const canAccessProject = (user, project) =>
  !user.organization_id ||
  can(user, [VIEW_PROJECTS]) ||
  (Array.isArray(project.user_ids) && project.user_ids.includes(user._id))

export const canViewScreenerRoom = (user, screenerRoom) =>
  (Array.isArray(screenerRoom.user_ids) && screenerRoom.user_ids.includes(user._id)) || can(user, [VIEW_SCREENER_ROOMS])

export const canModifyScreenerRoom = (user, screenerRoom) =>
  (Array.isArray(screenerRoom.user_ids) && screenerRoom.user_ids.includes(user._id)) ||
  can(user, [MODIFY_SCREENER_ROOMS])

export const canShareScreenerRoom = (user, screenerRoom) =>
  (Array.isArray(screenerRoom.user_ids) && screenerRoom.user_ids.includes(user._id)) || can(user, [SEND_SCREENER_ROOMS])

export const requiresPassword = (user, shareType) =>
  user.organization_id &&
  ((shareType === "screener" && can(user, [SCREENERS_REQUIRE_PASSWORD])) ||
    (shareType === "screenerRoom" && can(user, [SCREENER_ROOMS_REQUIRE_PASSWORD])))

export const requiresWatermark = (user, shareType) =>
  user.organization_id &&
  ((shareType === "screener" && can(user, [SCREENERS_REQUIRE_WATERMARK])) ||
    (shareType === "screenerRoom" && can(user, [SCREENER_ROOMS_REQUIRE_WATERMARK])))
