import {
  GET_FILE_REQUEST_PENDING,
  GET_FILE_REQUEST_FULFILLED,
  GET_FILE_REQUEST_FAILED,
  GET_FILE_REQUEST_SILENT_PENDING,
  GET_FILE_REQUEST_SILENT_FULFILLED,
  GET_FILE_REQUEST_SILENT_FAILED,
  SET_FILE_REQUEST,
  UPDATE_FILE_REQUEST,
  SELECT_EXISTING_FILE_REQUEST_PENDING,
  SELECT_EXISTING_FILE_REQUEST_FAILED,
  SELECT_EXISTING_FILE_REQUEST_FULFILLED
} from "@actions/file_request/"

const initialState = {
  files: [],
  status: "PENDING",
  import_status: "READY",
  request: {},
  select_existing_cinesend_file_open: false
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_FILE_REQUEST_PENDING:
      return {
        ...state,
        status: "PENDING"
      }
    case GET_FILE_REQUEST_FAILED:
      return {
        ...state,
        status: "ERROR"
      }

    case GET_FILE_REQUEST_FULFILLED:
    case GET_FILE_REQUEST_SILENT_FULFILLED:
      return {
        ...state,
        status: "READY",
        ...action.payload
      }

    case SET_FILE_REQUEST:
      return {
        ...state,
        status: "READY",
        ...action.result
      }

    case UPDATE_FILE_REQUEST:
      return {
        ...state,
        [action.key]: action.value
      }

    case SELECT_EXISTING_FILE_REQUEST_PENDING:
      return {
        ...state,
        import_status: "PENDING"
      }

    case SELECT_EXISTING_FILE_REQUEST_FAILED:
      return {
        ...state,
        import_status: "FAILED"
      }

    case SELECT_EXISTING_FILE_REQUEST_FULFILLED:
      return {
        ...state,
        ...action.payload.filerequest,
        import_status: "READY",
        select_existing_cinesend_file_open: false,
        request: action.payload.filerequest,
      }

    case GET_FILE_REQUEST_SILENT_PENDING:
    case GET_FILE_REQUEST_SILENT_FAILED:
    default:
      return state
  }
}
