import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { ButtonDropdown } from "@bitcine/cs-theme"
import { downloadFileWithHTTP } from "@api/transfer/download"

const DownloadButtonDropdown = ({ file, download, downloadFileWithHTTP }) =>
  !file.frozen_at ? (
    <ButtonDropdown
      button={{
        text: "Download",
        className: "white small ml2"
      }}
      dropdown={{
        clickCloses: true,
        content: [
          {
            text: {
              title: "Download file",
              description: "Standard download speeds"
            },
            icon: "file_download",
            onClick: e => downloadFileWithHTTP(file, download._id)
          }
        ]
      }}/>
  ) : null

DownloadButtonDropdown.propTypes = {
  downloadFileWithHTTP: PropTypes.func.isRequired,
  file: PropTypes.object.isRequired,
  download: PropTypes.object
}

const mapStateToProps = state => ({
  file: state.documentPreviewer.document,
  download: state.download_link
})

const mapDispatchToProps = dispatch => ({
  downloadFileWithHTTP: bindActionCreators(downloadFileWithHTTP, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(DownloadButtonDropdown)
