import React from "react"
import PropTypes from "prop-types"
import { DEFAULTS } from "@constants"
import { GLOBALS } from "@src/config"
import { Status } from "@bitcine/cs-theme"
import dayjs from "dayjs"
import Logo from "@src/public/logo.inline.svg"
import ExpiryDateTimePicker from "../../helpers/expiryDatetimePicker"

class Extension extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      status: "PENDING"
    }
  }
  componentDidMount() {
    this.api("GET", `${GLOBALS.API_URL}/shared-screeners/${this.props.match.params.sharedScreenerID}`, null, res =>
      this.setState({
        currentExpiry: res.sharedScreener.expires_at ? dayjs(res.sharedScreener.expires_at) : dayjs(),
        screenerName: res.name,
        sharedTo: res.sharedScreener.shared_name,
        status: "READY"
      })
    )
  }
  extend(newDate) {
    this.setState({ status: "PENDING", newExpiry: newDate }, () => {
      this.api(
        "PUT",
        `${GLOBALS.API_URL}/shared-screeners/${this.props.match.params.sharedScreenerID}/extension`,
        JSON.stringify({ expiry: newDate.toISOString() }),
        res => this.setState({ status: "SUCCESS" })
      )
    })
  }
  api(method, url, body, callback) {
    fetch(url, { ...DEFAULTS, method, body })
      .then(res => res.json())
      .then(res => callback(res))
      .catch(err => this.setState({ status: "ERROR" }))
  }
  render() {
    const { status, screenerName, sharedTo } = this.state
    return (
      <div
        style={{ zIndex: "10" }}
        /*
          This page in the app needs to be reponsive
          Since none of the app is responsive, setting this page as fixed above other components is a temp solution
        */
        className='fixed top-0 right-0 left-0 bottom-0 bg-gray-6 flex items-center justify-center p2'>
        <Status
          pending={status === "PENDING"}
          error={status === "ERROR"}>
          <div className='max-width-3 center mx-auto py4 px2 relative col-12 rounded bg-white'>
            <div className='bg-white fixed top-0 left-0 right-0'>
              <Logo className='mt2 mb1' style={{ maxWidth: "110px", maxHeight: "50px" }}/>
            </div>
            <h4>{screenerName}</h4>
            <div className='mb2'>
              {sharedTo}
            </div>
            <ExpiryDateTimePicker expiresAt={this.state.currentExpiry} onSave={newDate => this.extend(newDate)}/>
            {status === "SUCCESS" && <div className="mx4 mt1 p2 bg-green rounded">
              <span style={{ fontSize: "38px" }} className='material-icons'>
                check_circle_outline
              </span>
              <div>
                Successfully updated the expiry date to {dayjs.utc(this.state.newExpiry).local().format("MMM D, YYYY [at] h:mma")}!
              </div>
            </div>}
          </div>
        </Status>
      </div>
    )
  }
}

Extension.propTypes = {
  match: PropTypes.object.isRequired
}

export default Extension
