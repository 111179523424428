import React from "react"
import ReactDOM from "react-dom"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import styled, { keyframes } from "react-emotion"
import { Status } from "@bitcine/cs-theme"
import { DEFAULTS } from "@constants"
import { previewDocument } from "@api/project/files/preview"
import { bindActionCreators } from "redux"
import findIndex from "lodash.findindex"
import Header from "./components/header"
import Image from "./components/image"
import Document from "./components/document"
import Audio from "./components/audio"
import Screener from "./components/screener"
import InvalidDocument from "./components/invalid_document"
import { GLOBALS } from "@src/config"

const fade = keyframes`
	from { opacity: 0; }
	to { opacity: 1; }
`

const Wrap = styled("section")`
  background: radial-gradient(circle, rgba(76, 81, 88, 0.8) 0%, #35393f 100%);
  z-index: 10;
  opacity: 0;
  animation: ${fade} 0.2s linear forwards;
`

const Content = styled("div")`
  height: calc(100vh - 5em);
`
const NextButton = styled("button")`
  z-index: 11;
  display: block;
  position: fixed;
  right: 0;
  top: 50%;
  margin-top: -25px;
  height: 50px;
  width: 50px;
  min-width: 50px;
  color: #fff;
  background: rgba(0, 0, 0, 0.28);
  border-radius: 2px;
  cursor: pointer;
`

const PrevButton = styled(NextButton)`
  left: 0;
  right: auto;
`

class DocumentPreviewer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      signed_url: "",
      status: "PENDING",
      is_image: false,
      is_audio: false,
      is_video: false
    }
  }
  componentDidMount() {
    document.body.style.overflow = "hidden"
    this.fetchFile()
    document.addEventListener("keydown", this.handleKeyBind)
  }
  componentDidUpdate(prevProps) {
    if (prevProps.document._id !== this.props.document._id) {
      this.fetchFile()
    }
  }
  componentWillUnmount() {
    document.body.style.overflow = "auto"
    document.removeEventListener("keydown", this.handleKeyBind)
  }
  handleKeyBind = e => {
    if (e.key === "ArrowRight") {
      this.loadFile("next")
    }

    if (e.key === "ArrowLeft") {
      this.loadFile("prev")
    }
  }
  loadFile = (dir = "next") => {
    const { files, previewDocument, document } = this.props
    const toAdd = dir === "next" ? 1 : -1
    var idx = findIndex(files, file => file._id === document._id)

    let fileIndex = (idx === 0 && dir === "prev")
      ? files.length - 1
      : (idx === files.length - 1 && dir === "next")
        ? 0
        : idx + toAdd
    let file = files[fileIndex]
    previewDocument(file)
  }
  fetchFile = () => {
    this.setState(
      {
        is_image: this.props.document.kind === "Image",
        is_audio: this.props.document.kind === "Audio Track",
        is_document: this.props.document.kind === "Document" || this.props.document.kind === "PDF",
        is_video: !!this.props.document.screener
      },
      () => {
        const isValid = this.state.is_image || this.state.is_audio || this.state.is_document || this.state.is_video
        // If not a valid document type, set status to READY and we'll display an empty preview box.
        if (!isValid) {
          this.setState({ status: "READY" })
          return
        }
        this.setState({ status: "PENDING" }, () => {
          const params = `projectID=${this.props.projectID}&fileID=${this.props.document._id}&fileType=${
            this.props.document.type
          }`

          fetch(`${GLOBALS.API_URL}/s3/download?${params}`, DEFAULTS)
            .then(res => res.json())
            .then(res =>
              this.setState({
                signed_url: res.token,
                status: "READY"
              })
            )
            .catch(err => this.setState({ status: "ERROR" }))
        })
      }
    )
  }
  render() {
    const { status, is_document, is_image, is_audio, is_video, signed_url } = this.state
    return ReactDOM.createPortal(
      <React.Fragment>
        <NextButton onClick={() => this.loadFile("next")} disabled={status === "PENDING"}>
          <i className='material-icons'>keyboard_arrow_right</i>
        </NextButton>
        <PrevButton onClick={() => this.loadFile("prev")} disabled={status === "PENDING"}>
          <i className='material-icons'>keyboard_arrow_left</i>
        </PrevButton>
        <Wrap className='fixed top-0 left-0 right-0 bottom-0 white'>
          <Header hideActions={this.props.hideActions}/>
          <Content className='flex items-center justify-center'>
            <Status pending={status === "PENDING"} error={status === "ERROR"}>
             { 
              is_video ? <Screener screener={this.props.document.screener}/>
                : is_image ? <Image url={signed_url}/>
                : is_audio ? <Audio url={signed_url}/>
                : is_document ? <Document url={signed_url}/>
                : <InvalidDocument/>
              }
            </Status>
          </Content>
        </Wrap>
      </React.Fragment>,
      document.body
    )
  }
}

DocumentPreviewer.propTypes = {
  document: PropTypes.object.isRequired,
  extension: PropTypes.string.isRequired,
  files: PropTypes.array.isRequired,
  previewDocument: PropTypes.func.isRequired,
  projectID: PropTypes.string.isRequired,
  hideActions: PropTypes.bool
}

DocumentPreviewer.defaultProps = {
  hideActions: false
}

const mapStateToProps = state => {
  let files = state.download_link.files.status === 'READY'
    ? state.download_link.files.list
    : state.project.files.items.list.filter(file => file.type !== "folder")
  return {
    document: state.documentPreviewer.document,
    extension: state.documentPreviewer.document.extension,
    files,
    projectID: state.documentPreviewer.document.project_id
  }
}

const mapDispatchToProps = dispatch => ({
  previewDocument: bindActionCreators(previewDocument, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentPreviewer)
