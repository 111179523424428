import {
  GET_FILES,
  UPDATE_FILES,
  UPDATE_FILE,
  SAVE_FILE,
  DELETE_FILE,
  CREATE_PACKAGE,
  GET_FOLDER_CONTENT,
  REPLACE_FILE,
  UPDATE_FILE_PREVIEW_IMAGE,
  RESET_PAGINATION,
  UPDATE_SEARCH,
  MOVE_FILES_TO_PROJECT
} from "@actions/project/files"

import { addGlobalMessage } from "@api/utils/"
import { get, post, del } from "@api/fetch"

/**
 * Get Files
 *
 * @param {String} projectID
 * @param {String} folderID
 *
 * @returns {Function} dispatch
 */

export const getFiles = (projectID, folderID = "", skip = 0) => (dispatch, getState) => {
  let search = getState().project.files.items.searchTerm
  let take = getState().project.files.items.pagination.take
  dispatch(
    get(GET_FILES, `projects/${projectID}/files?skip=${skip}&take=${take}&folderID=${folderID}&search=${search}`)
  )
}

/**
 * Search Files
 *
 * @param {String} projectID
 * @param {String} term
 *
 * @returns {Function} dispatch
 */

export const searchFiles = (projectID, term) => (dispatch, getState) => {
  dispatch(get(GET_FILES, `projects/${projectID}/search-files?term=${term}`))
}

export const resetPagination = (hidden = false) => dispatch => {
  dispatch({ type: RESET_PAGINATION, payload: { hidden } })
}

/**
 * Update Files
 *
 * @param {String} key
 * @param {String} value
 *
 * @returns {Function} dispatch
 */

export const updateFiles = (key, value) => dispatch => dispatch({ type: UPDATE_FILES, key, value })

/**
 * Updates File
 * - manages the state of a file
 *
 * @returns dispatch
 */

export const updateFile = (fileID, key, value) => dispatch => dispatch({ type: UPDATE_FILE, fileID, key, value })

/**
 * Toggle checked file
 *
 * @param {String} fileID
 *
 * @returns {Function} dispatch
 */

export const toggleCheckedFile = fileID => (dispatch, getState) => {
  const files = getState().project.files.items.checked
  dispatch(updateFiles("checked", files.includes(fileID) ? files.filter(_id => _id !== fileID) : [...files, fileID]))
}

/**
 * Share Files
 * - post to server with an array of email to send download links
 *
 * @returns dispatch
 */

export const shareFiles = () => (dispatch, getState) => {
  const info = getState().project.files.modals.share
  dispatch(
    post(
      CREATE_PACKAGE,
      `projects/${getState().project.details._id}/packages`,
      {
        projectID: getState().project.details._id,
        shareType: "email",
        method: "http",
        _files: info.files,
        emails: info.emails.map(email => email.value),
        message: info.message,
        label: getState().project.files.modals.share.label,
        password: info.password
      },
      () => dispatch(addGlobalMessage(`File shared`, "success"))
    )
  )
}

/**
 * Create public URL
 * - only for http
 *
 * @returns dispatch
 */

export const createPublicLink = () => (dispatch, getState) => {
  const info = getState().project.files.modals.share
  return dispatch(
    post(CREATE_PACKAGE, `projects/${getState().project.details._id}/packages`, {
      projectID: getState().project.details._id,
      shareType: "link",
      method: "http",
      _files: info.files,
      label: info.label,
      password: info.password
    })
  )
}

/**
 * Save File
 * - manages the file values and posts to server
 *
 * @param {String} - fileID
 * @param {string} - fileType
 * @param {String} - key
 * @param {String or Array or Object} - value
 *
 * @returns dispatch
 */

export const saveFile = (fileID, fileType, key, value) => (dispatch, getState) =>
  dispatch(
    post(SAVE_FILE, `projects/${getState().project.details._id}/files`, {
      fileID,
      fileType,
      key,
      value
    })
  )

/**
 * Move Files To New Project
 * - moves files to a new project ID
 *
 * @param {Array} - files
 * @param {String} - destinationProjectID
 *
 * @returns dispatch
 */

export const moveFilesToProject = (_files, destinationProjectID) => (dispatch, getState) => {
  const folderID = null //getState().routerParams.location ? getState().routerParams.location.query.folderID : null
  dispatch(
    post(
      MOVE_FILES_TO_PROJECT,
      `projects/${getState().project.details._id}/files/move`,
      {
        _files,
        destinationProjectID,
        folderID
      },
      () => dispatch(addGlobalMessage(`Triggered Moving File${_files.length > 1 ? "s" : ""}!`, "success"))
    )
  )
}

/**
 * Delete File
 *
 * @param {String} fileID
 *
 * @returns {Function} dispatch
 */

export const deleteFiles = (_files, folderID = null) => (dispatch, getState) => {
  dispatch(
    del(
      DELETE_FILE,
      `projects/${getState().project.details._id}/files`,
      {
        _files,
        folderID: folderID
      },
      () => dispatch(addGlobalMessage(`File${_files.length > 1 ? "s" : ""} Deleted!`, "success"))
    )
  )
}

/**
 * Get Folder Content
 * - gets the files that are in a dcp
 *
 * @returns dispatch
 */

export const getDCPContent = dcp => (dispatch, getState) =>
  dispatch(get(GET_FOLDER_CONTENT, `projects/${dcp.project_id}}/dcps/${dcp._id}/content`))

export const replaceFile = file => dispatch => dispatch({ type: REPLACE_FILE, payload: { file } })
export const updateProjectPreviewImage = (id, status, url, url_sm, url_md, url_lg, key) => dispatch =>
  dispatch({
    type: UPDATE_FILE_PREVIEW_IMAGE,
    payload: { id, status, url, url_sm, url_md, url_lg, key }
  })

export const updateSearch = searchTerm => dispatch =>
  dispatch({
    type: UPDATE_SEARCH,
    payload: {
      searchTerm
    }
  })
