import React from "react"
import PropTypes from "prop-types"
import dayjs from "dayjs"

class ExpiryDateTimePicker extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentExpiry: props.expiresAt ? dayjs(props.expiresAt) : null,
      newExpiry: dayjs().add(1, "hour").startOf("hour")
    }
  }
  save() {
    this.setState({ pending: true })
    this.props.onSave(dayjs(this.state.newExpiry))
  }
  render() {
    return (
      <div className="center">
        <div className='mt2'>
          Current expiry:
        </div>
        {this.state.currentExpiry ? <>
          <div>
            Local time: <b>{dayjs.utc(this.state.currentExpiry).local().format("MMM D, YYYY [at] h:mma")}</b>.
          </div>
          <div className=''>
            UTC: <b>{dayjs.utc(this.state.currentExpiry).format("MMM D, YYYY [at] h:mma")}</b>.
          </div></> : <div>No expiry date currently set.</div>}
        <div className='my2'>
          You may modify the expiry date and time by selecting a new value below.
        </div>
        <div className='flex justify-center'>
          <input
            id='date'
            style={{ fontFamily: "Roboto" }}
            placeholder='New Expiry Date'
            className='cs-input max-width-1'
            value={this.state.newExpiry.format("YYYY-MM-DDTHH:mm")}
            onChange={e =>
              this.setState({
                newExpiry: dayjs(e.target.value || dayjs().format("YYYY-MM-DD")) 
              })
            }
            type='datetime-local'/>
        </div>
        <div className='mt2'>
          New expiry:
        </div>
        <div>
          Local time: <b>{dayjs.utc(this.state.newExpiry).local().format("MMM D, YYYY [at] h:mma")}</b>.
        </div>
        <div className=''>
          UTC: <b>{dayjs.utc(this.state.newExpiry).format("MMM D, YYYY [at] h:mma")}</b>.
        </div>
        <button onClick={() => this.save()} disabled={!this.state.newExpiry || this.state.pending} className='cs-button mt2'>
          Confirm Expiry Date
        </button>
      </div>
    )
  }
}

ExpiryDateTimePicker.propTypes = {
  expiresAt: PropTypes.object,
  onSave: PropTypes.func
}

export default ExpiryDateTimePicker
