import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Checkbox, Modal, Status } from "@bitcine/cs-theme"
import { toggleModalVisibility } from "@api/project/files/modals"
import { deleteFiles } from "@api/project/files/"
import 'styled-components/macro'
import { withRouter } from "react-router-dom"

class DeleteFiles extends React.Component {
  constructor() {
    super()

    this.state = {
      text: "",
      links: false
    }
  }
  render() {
    const { deletingFiles, files, match } = this.props
    const folderID = match.params.folderID
    const trail = files.length > 1 ? "s" : ""
    return (
      <Modal
        open
        onClose={() => this.props.toggleModalVisibility("delete")}
        title={`Delete ${files.length} item${trail}`}>
        <Status pending={deletingFiles === "PENDING"} error={deletingFiles === "ERROR"}>
          <div>
            <div
              className='border-gray-5 border-bottom pb2'
              css={`
                white-space: pre-line;
                overflow-wrap: break-word;
              `}>
              <div className='red flex items-center pb1'>
                <span className='material-icons mr1'>error</span>
                Delete {files.length} item
                {trail}:{" "}
              </div>
              {files.map((file, i) => (
                <div key={i} className='red'>
                  <strong>{file.type === "folder" ? `- (${file.name}) Folder` : `- ${file.name}`}</strong>
                </div>
              ))}
            </div>
            <div className='py2'>
              <strong> Warning, this cannot be undone. </strong>
              Please verify you wish to delete these items from your project and acknowledge the change below.
            </div>
            <label className='cs-label flex items-center py1'>
              <Checkbox checked={this.state.links} onChange={() => this.setState({ links: !this.state.links })}/>
              <span className='ml1'>All share links will be inaccessible</span>
            </label>
            <div className='flex justify-end items-center mt3'>
              <button
                className='cs-button'
                disabled={deletingFiles === "PENDING" || !this.state.links}
                onClick={() => {
                  this.props.deleteFiles(files, folderID)
                }}>
                Delete item
                {trail}
              </button>
            </div>
          </div>
        </Status>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  ...state.projects,
  deletingFiles: state.project.files.modals.delete.status,
  files: state.project.files.modals.delete.files
})

const mapDispatchToProps = dispatch => ({
  deleteFiles: bindActionCreators(deleteFiles, dispatch),
  toggleModalVisibility: bindActionCreators(toggleModalVisibility, dispatch)
})

DeleteFiles.propTypes = {
  deleteFiles: PropTypes.func.isRequired,
  deleteProjects: PropTypes.func.isRequired,
  closeErrorModal: PropTypes.func.isRequired,
  deletingFiles: PropTypes.object.isRequired,
  toggleModalVisibility: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteFiles))

