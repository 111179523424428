import React from "react"
import PropTypes from "prop-types"
import { Modal } from "@bitcine/cs-theme"
import ExpiryDateTimePicker from "./expiryDatetimePicker"

class DayPickerModal extends React.Component {
  render() {
    return (
      <Modal title={this.props.title} open={true} onClose={() => this.props.onClose()}>
        <ExpiryDateTimePicker {...this.props} expiresAt={this.props.expiry}/>
      </Modal>
    )
  }
}

DayPickerModal.propTypes = {
  title: PropTypes.string.isRequired,
  expiry: PropTypes.object.isRequired,
  onSave: PropTypes.func,
  onClose: PropTypes.func.isRequired
}

export default DayPickerModal
