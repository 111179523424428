import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Link } from "react-router-dom"
import Dropzone from "react-dropzone"
import { canModifyScreenerRoom } from "@src/helpers/authorization"
import { initializeHttpUpload } from "@api/transfer/http/upload"
import "../styles.scss"
import Empty from "./empty"
import Title from "./title"
import OverlayActions from "./overlay_actions"

class Screener extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      url: props.screener.thumbnail_url
    }
  }
  render() {
    const { screener, project, initializeHttpUpload, organizationId, screenerRoom, canModify } = this.props

    const thumbnailStyle = this.state.url
      ? { backgroundImage: `url(${this.state.url})` }
      : { backgroundColor: "#ECEEEF" }

    let dropRef

    return (
      <div className='xl-col-3 lg-col-4 md-col-6 col-6 pr2 mt2'>
        <Link to={`/projects/${screener.project_id}/files/preview-link/${screener._id}`}>
          <Dropzone
            multiple={false}
            accept='image/*'
            ref={node => (dropRef = node)}
            activeClassName={"activeDropzone"}
            className='border-none'
            disableClick={true}
            disabled={!canModify}
            onDrop={(accepted, rejected) => {
              this.setState({ showOptions: false })
              accepted.map(file =>
                initializeHttpUpload(
                  file,
                  {
                    project_id: screener.project_id,
                    screener_id: screener._id
                  },
                  () => {
                    const reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onload = () => {
                      this.setState({ url: file.preview })
                    }
                  }
                )
              )
            }}>
            <div className={"container"} style={thumbnailStyle}>
              <div className={`inner z3`}>
                <OverlayActions
                  screener={screener}
                  project={project}
                  canModify={canModify}
                  updateThumbnail={() => dropRef.open()}/>
                <Title organizationId={organizationId} screenerRoom={screenerRoom} screener={screener}/>
              </div>
              {!screener.thumbnail_url && <Empty/>}
            </div>
          </Dropzone>
        </Link>
      </div>
    )
  }
}

Screener.propTypes = {
  screener: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  initializeHttpUpload: PropTypes.func.isRequired,
  organizationId: PropTypes.string.isRequired,
  screenerRoom: PropTypes.object.isRequired,
  canModify: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  organizationId: state.user.auth.organization_id,
  screenerRoom: state.screener_rooms.screenerRoom.data,
  canModify: canModifyScreenerRoom(state.user.auth, state.screener_rooms.screenerRoom.data)
})

const mapDispatchToProps = dispatch => ({
  initializeHttpUpload: bindActionCreators(initializeHttpUpload, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Screener)
