import uuidv4 from "uuid/v4"
import { POST_DEFAULTS, S3_FINE_UPLOADER_DEFAULTS } from "@constants"
import fileTypeChecker from 'file-type-checker';

/**
 * Validates fine uploader params
 *
 * @param {Object} file
 * @param {Object} specifications - optional data to include in the http request
 * @param {Function} completionCallback - called after upload completion
 * @param {Function} progressCallback - called during upload progress
 *
 * @returns None
 */

export const validateFineUploaderParams = (file, specifications, completionCallback, progressCallback) => {
  if (!file || typeof file !== "object") throw new Error("File is required.")
  if (completionCallback && typeof completionCallback !== "function") throw new Error("Callback must be a function.")
  if (progressCallback && typeof progressCallback !== "function") throw new Error("Callback must be a function.")
}
 

const readFileAsArrayBuffer = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
  reader.readAsArrayBuffer(file);
});

/**
 * Checks for file type and returns a promise which results in a 
 * boolean if the file type matches the extension in the filename
 *
 * @param {Object} fileToUpload
 *
 * @returns {Promise}
 */
export const isValidFileType = (fileToUpload) => { 
  //Is this an array of files? If so lets bypass the check.
  if(isDirectory(fileToUpload)){
    return Promise.resolve(true)
  }

  // This is a list of file types that can be uploaded but file-type-checker cant validate
  // const allowList = ['csv', 'txt', 'svg'] 
  return readFileAsArrayBuffer(fileToUpload)
    .then(buffer => {  
      return fileTypeChecker.detectFile(buffer) ?? false;
    })
    .then(fileType => { 

      //Return true if the package cannot determine file type
      if(!fileType) {
        return true
      }

      try {
        // Make sure we have a proper extension from the file 
        // name of the file being uploaded otherwise return false
        const filename = fileToUpload?.name || fileToUpload?.upload_details?.file_name
        if (!filename || typeof filename !== 'string' || filename.trim() === '') {
          return false;
        }

        const lastDotIndex = filename.lastIndexOf('.');

        // If no dot is found or dot is at the start (hidden file), return false
        if (lastDotIndex === -1 || lastDotIndex === 0) {
          return false;
        }

        const uploadedFileExtension = filename.slice(lastDotIndex + 1);
        
        // Check if the detected extension matches fileDetails.extension. If so return true otherwise return false
        return (
          fileType?.extension === uploadedFileExtension || fileToUpload?.type === fileType?.mimeType
          || fileType?.signature?.compatibleExtensions.includes(uploadedFileExtension)

          //Add this condition if using the allow list
          //|| allowList.includes(uploadedFileExtension)
        )
      } catch(error) {
        console.log('FileType Bypass due to: ', err);
        return true;
      }

  })
  .catch(err => {
    console.log('FileType Unknown: ', err);
    return true;
  });
 
}

/**
 * Parses
 *
 * @param {Object} file
 * @param {Object} specifications
 *
 * @returns {Object}
 */

export const parseFineUploaderData = (file, specifications) => ({
  isDirectory: isDirectory(file),
  size: calculateFileSize(file),
  name: parseFileName(file),
  path: parseFilePath(file),
  uuid: uuidv4(),
  opts: {
    method: "HTTP",
    ...POST_DEFAULTS,
    body: JSON.stringify({
      method: "HTTP",
      file_list: [
        {
          isDirectory: isDirectory(file),
          name: parseFileName(file),
          path: parseFilePath(file),
          size: calculateFileSize(file)
        }
      ],
      specifications
    })
  }
})

/**
 * Parses the correct file path
 *
 * @param {Object} file
 *
 * @returns {String} path
 */

const parseFilePath = file => (isDirectory(file) ? "" : file.preview)

/**
 * Validates if the transfer is a directory or file
 *
 * @param {Object} file
 *
 * @returns {Bool} directory
 */

const isDirectory = file => file.length > 0

/**
 * Calculates the size of a transfer
 * Note: Transfers can have one file or be a directory with many files
 *
 * @param {Object} file
 *
 * @returns {Int} total size
 */

const calculateFileSize = file =>
  parseFloat(isDirectory(file) ? file.reduce((totalSize, file) => totalSize + file.size, 0) : file.size)

/**
 * Parses the file name
 * Note: Transfers that are directories need the pathname added to the file name
 *
 * @param {Object} file
 *
 * @returns {String} file name
 */

const parseFileName = file => (isDirectory(file) ? file[0].webkitRelativePath.split("/")[0] : file.name)

/**
 * Creates a transfer object to add to state
 *
 * @param {Object} uploadDetails - returned from the server
 * @param {Int} size
 * @param {Bool} isDirectory
 * @param {String} uuid
 *
 * @returns {Object} transfer
 */

export const createTransfer = (uploadDetails, uuid, completionCallback = null, progressCallback = null) => ({
  upload_id: uploadDetails._id,
  name: uploadDetails.file_name,
  total_size: uploadDetails.total_size,
  current_size: 0,
  progress: "0%",
  status: "Preparing",
  isParent: true,
  method: uploadDetails.upload_method,
  type: "upload",
  uuid,
  transfer_iteration_token: 0,
  childrenTransfers: {},
  completionCallback,
  progressCallback
})

/**
 * Get File Data
 *
 * @param {Object} file
 * @param {String} parentUUID - the transfers partent id
 * @param {Object} endpointDetails
 * @param {Object} specifications
 * @param {Function} callback
 *
 * @returns {Object}
 */

export const getFileData = (file, isPartOfDirectory, uuid, endpointDetails, specifications = {}) => {
  const fileUUID = uuidv4()
  const endpoint = `${S3_FINE_UPLOADER_DEFAULTS.request.endpoint}`

  let destination = endpointDetails.upload_details.destination
  if (isPartOfDirectory) {
    destination = destination + file.webkitRelativePath
  }

  file.parentUUID = uuid
  file.transferUUID = fileUUID
  file.is_part_of_directory = isPartOfDirectory
  file.upload_details = endpointDetails.upload_details
  file.destination = destination

  return { parsedFile: file, fileUUID, endpoint }
}
