import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import humanFileSize from "@src/helpers/humanFileSize"
import formatDate from "@src/helpers/formatDate"
import { DEFAULTS } from "@constants"
import { GLOBALS } from "@src/config"

const Files = ({ fileRequest }) => {
  const [files, setFiles] = useState(fileRequest.files ?? [])
  useEffect(() => {
    setFiles(fileRequest.files ?? [])
  }, [fileRequest])
  return (
    files.length > 0 && (
      <div className='max-width-3 mx-auto mt2'>
        <strong className='block muted mb1'>Provided Files ({files.length})</strong>
        {files.map(file => (
          <div key={file._id} className='p2 bg-white rounded box-shadow flex items-center mb1'>
            <div className='col-8 flex items-center'>
              <span style={{ fontSize: "16px" }} className='material-icons green mr1'>
                check_circle
              </span>
              <strong className='truncate'>
                {file.name} <span className='muted small'>({humanFileSize(file.size)})</span>
              </strong>
            </div>
            <div className='col-4'>
              <div className='flex items-center justify-end'>
                <strong className='truncate'>{formatDate(file.created_at)}</strong>
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  )
}

Files.propTypes = {
  fileRequest: PropTypes.object
}

const mapStateToProps = ({ file_request }) => ({
  fileRequest: file_request
})

export default connect(
  mapStateToProps,
  null
)(Files)
